import { useState } from "react";
import Logo from "./../../../../public/img/medorn-logo.png";
import MobileNav from "../../../../components/MobileNav";
import { MdSearch, MdClose } from "react-icons/md";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { Link } from "react-router-dom";

const Header = () => {
  const [search, setSearch] = useState("");
  const [showMobileNav, setShowMobileNav] = useState(false);
  const toggleShowMobileNav = () => setShowMobileNav((prevState) => !prevState);

  const handleSubmit = () => {
    console.log(search);
  };

  return (
    <div className="main-nav">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 col-lg-2 logo-col">
            <Link to="/">
              <img src={Logo} alt="Medorn Logo" className="main-nav-logo" />
            </Link>
          </div>

          <div className="col-md-8 col-lg-8 search-col">
            <form onSubmit={handleSubmit} className="search-form">
              <input
                type="text"
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                placeholder="Search something in the documentation"
              />
              <button type="submit">
                <MdSearch className="search-icon" />
              </button>
            </form>
          </div>

          <div onClick={toggleShowMobileNav} className="hamburger-col">
            {showMobileNav ? (
              <MdClose className="mobile-nav-icon" />
            ) : (
              <HiOutlineMenuAlt3 className="mobile-nav-icon" />
            )}
          </div>

          {showMobileNav ? <MobileNav /> : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
