import YouTubePlayer from "react-player/youtube";

const SecondarySales = () => {
  return (
    <div className="container ps-5">
      <div className="row">
        <h1 className="content-heading">Submitting Secondary Sales</h1>
        <p>
          This video shows how Medical Representatives can submit their
          secondary sales in the software.
        </p>

        <div className="video-container">
          <YouTubePlayer url="https://youtu.be/kP_7wLlTFts" controls={true} />
        </div>
      </div>
    </div>
  );
};

export default SecondarySales;
