import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Creation,
  Dashboard,
  DCR,
  DeviationAndAdditional,
  Extras,
  GeoTagging,
  Home,
  HomeContent,
  SecondarySales,
  TourProgram,
  Util,
} from "./views";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="/" element={<HomeContent />} />
            <Route path="/tour-program" element={<TourProgram />} />
            <Route path="/dcr" element={<DCR />} />
            <Route path="/geo-tagging" element={<GeoTagging />} />
            <Route
              path="/deviation-and-additional"
              element={<DeviationAndAdditional />}
            />
            <Route path="/creation" element={<Creation />} />
            <Route path="/utilities" element={<Util />} />
            <Route path="/extras" element={<Extras />} />
            <Route path="/secondary-sales" element={<SecondarySales />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
