import YouTubePlayer from "react-player/youtube";

const GeoTagging = () => {
  return (
    <div className="container ps-5">
      <div className="row">
        <h1 className="content-heading">Geo Tagging in Medorn ERP</h1>
        <p className="video-desc">
          This video shows how MR and the employees working in the field can tag
          their location while visiting a Doctor, Chemist, or Stockist. The
          video also explains how this feature works considering the privacy of
          employees.
        </p>
        <div className="video-container">
          <YouTubePlayer url="https://youtu.be/RMVKNtr4Rig" controls={true} />
        </div>
      </div>
    </div>
  );
};

export default GeoTagging;
