import { Link } from "react-router-dom";
import {
  MdOutlineMap,
  MdNoteAlt,
  MdShareLocation,
  MdEditNote,
  MdEditCalendar,
  MdMoney,
  MdOutlineDashboard,
} from "react-icons/md";
import { FaDeviantart, FaStickyNote } from "react-icons/fa";

const SideNav = () => {
  return (
    <div className="docs-sidenav">
      <nav>
        <ul>
          <Link to="/tour-program">
            <li>
              <MdOutlineMap className="docs-sidenav-icons" />
              <span>Tour Program</span>
            </li>
          </Link>

          <Link to="/dcr">
            <li>
              <MdNoteAlt className="docs-sidenav-icons" />
              <span>Daily Call Report</span>
            </li>
          </Link>

          <Link to="/geo-tagging">
            <li>
              <MdShareLocation className="docs-sidenav-icons" />
              <span>Geo Tagging</span>
            </li>
          </Link>

          <Link to="/deviation-and-additional">
            <li>
              <FaDeviantart className="docs-sidenav-icons" />
              <span>Deviation & Additional</span>
            </li>
          </Link>

          <Link to="/creation">
            <li>
              <MdEditNote className="docs-sidenav-icons" />
              <span>Creation</span>
            </li>
          </Link>

          <Link to="/utilities">
            <li>
              <FaStickyNote className="docs-sidenav-icons" />
              <span>Utilities</span>
            </li>
          </Link>

          <Link to="/extras">
            <li>
              <MdEditCalendar className="docs-sidenav-icons" />
              <span>Extras</span>
            </li>
          </Link>

          <Link to="/secondary-sales">
            <li>
              <MdMoney className="docs-sidenav-icons" />
              <span>Secondary Sales</span>
            </li>
          </Link>

          <Link to="/dashboard">
            <li>
              <MdOutlineDashboard className="docs-sidenav-icons" />
              <span>Dashboard</span>
            </li>
          </Link>
        </ul>
      </nav>
    </div>
  );
};

export default SideNav;
