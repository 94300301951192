import YouTubePlayer from "react-player/youtube";

const Dashboard = () => {
  return (
    <div className="container ps-5">
      <div className="row">
        <h1 className="content-heading">Dashboard in Web App</h1>
        <p>
          This video shows how Medical Representatives can submit their
          secondary sales in the software.
        </p>

        <div className="video-container">
          <YouTubePlayer url="https://youtu.be/l__DtYBdZhw" controls={true} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
