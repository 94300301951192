import YouTubePlayer from "react-player/youtube";

const DeviationAndAdditional = () => {
  return (
    <div className="container ps-5">
      <div className="row">
        <h1 className="content-heading">
          Understanding Deviation & Additional Option in Medorn ERP
        </h1>
        <p className="video-desc">
          The video shows how deviation and additional option works in the Daily
          Call Report feature & Geo Tagging as well.
        </p>

        <div className="video-container">
          <YouTubePlayer url="https://youtu.be/V1pyjHBUgdA" controls={true} />
        </div>
      </div>
    </div>
  );
};

export default DeviationAndAdditional;
