const HomeContent = () => {
  return (
    <div className="container ps-5 mt-5">
      <div className="row">
        <p>
          Medorn is an advanced, real-time SaaS analytics platform, designed for
          Pharmaceuticals Companies to track the progress and efficiency of
          their agents on the field as well as their Medicinal Products. The
          platform is powered by its own database technology that enables the
          processing of the analytical data very easily helping the users to
          collect and utilize data for the benefit of the company by increasing
          the efficiency of the work.
        </p>

        <p>
          Medorn is a cloud-hosted solution, so you can access it with any
          browser or using our dedicated mobile apps available on Android and
          iOS(Coming Soon). This is Medorn's Document center and it is made to
          help the user to learn how to use Medorn with 100% efficiency. We have
          added multiple videos here to explain each and every feature that
          Medorn offers. You can watch these videos and learn how to use Medorn
          in a matter of minutes.{" "}
        </p>

        <p>
          We also have added different videos for different devices, so you can
          learn to use the same feature on Android as well as on Desktop. We
          will keep this document updated so that with every update you can
          visit this website and find a video on the new features we have added.
          And since Medorn offers 24/7 support you can always reach out to us
          with any problem and we will be more then happy to provide you with
          the solution.
        </p>
      </div>
    </div>
  );
};

export default HomeContent;
