import YouTubePlayer from "react-player/youtube";

const DCR = () => {
  return (
    <div className="container ps-5">
      <div className="row">
        <h1 className="content-heading">
          Submitting Daily Call Report in Medorn ERP
        </h1>
        <p className="video-desc">
          The video shows how employees can fill out their Daily Call Report in
          the software.
        </p>
        <div className="video-container">
          <YouTubePlayer url="https://youtu.be/tWk7u9Y4bOI" controls={true} />
        </div>
      </div>
    </div>
  );
};

export default DCR;
