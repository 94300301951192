import YouTubePlayer from "react-player/youtube";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useState } from "react";

const TourProgram = () => {
  const [value, setValue] = useState(0);

  return (
    <div className="container ps-5">
      <div className="row">
        <Paper square>
          <Tabs
            value={value}
            textColor="primary"
            indicatorColor="primary"
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <Tab label="Mobile / Android App" className="activity-tabs" />
            <Tab label="Desktop App" className="activity-tabs" />
          </Tabs>
          {value === 0 ? (
            <>
              <h1 className="content-heading">
                Creating Tour Program in Medorn ERP
              </h1>
              <p>
                This video shows how MR's and other employees can create their
                Tour Program and get it approved by their managers.
              </p>

              <div className="video-container">
                <YouTubePlayer
                  url="https://youtu.be/MKFWzvURG0A"
                  controls={true}
                />
              </div>
            </>
          ) : value === 1 ? (
            <>
              <h1 className="content-heading">
                Tour Program in Medorn Web App
              </h1>
              <p>
                This video shows how MR's and other employees can create their
                Tour Program and get it approved by their managers in Web App /
                Desktop / Laptop.
              </p>

              <div className="video-container">
                <YouTubePlayer
                  url="https://youtu.be/9btm7pY-_wU"
                  controls={true}
                />
              </div>
            </>
          ) : null}
        </Paper>
      </div>
    </div>
  );
};

export default TourProgram;
