import YouTubePlayer from "react-player/youtube";

const Creation = () => {
  return (
    <div className="container ps-5">
      <div className="row">
        <h1 className="content-heading">Creation Menu Overview</h1>
        <p className="video-desc">
          This video shows how Medical Representatives can create Doctors,
          Chemists & Stockists and get them approved by their managers.
        </p>
        <div className="video-container">
          <YouTubePlayer url="https://youtu.be/9b-4IFgE7gE" controls={true} />
        </div>
      </div>
    </div>
  );
};

export default Creation;
