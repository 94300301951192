import { Link } from "react-router-dom";
import {
  MdOutlineMap,
  MdNoteAlt,
  MdShareLocation,
  MdEditNote,
  MdEditCalendar,
  MdMoney,
  MdOutlineDashboard,
} from "react-icons/md";
import { FaDeviantart, FaStickyNote } from "react-icons/fa";
import { useState } from "react";

const MobileNav = () => {
  const [showMobileNav, setShowMobileNav] = useState(true);
  const toggleShowMobileNav = () => setShowMobileNav((prevState) => !prevState);

  return (
    <>
      {showMobileNav ? (
        <div className="mobileNav">
          {/* <ul>
          <Link to="/" className="mobileNavLinks">
            <li>
              <AiFillHome className="mobileNavIcon" />
              Home
            </li>
          </Link>
        </ul> */}

          <ul>
            <Link
              to="/tour-program"
              className="mobileNavLinks"
              onClick={toggleShowMobileNav}
            >
              <li>
                <MdOutlineMap className="mobileNavIcon" />
                <span>Tour Program</span>
              </li>
            </Link>

            <Link
              to="/dcr"
              className="mobileNavLinks"
              onClick={toggleShowMobileNav}
            >
              <li>
                <MdNoteAlt className="mobileNavIcon" />
                <span>Daily Call Report</span>
              </li>
            </Link>

            <Link
              to="/geo-tagging"
              className="mobileNavLinks"
              onClick={toggleShowMobileNav}
            >
              <li>
                <MdShareLocation className="mobileNavIcon" />
                <span>Geo Tagging</span>
              </li>
            </Link>

            <Link
              to="/deviation-and-additional"
              className="mobileNavLinks"
              onClick={toggleShowMobileNav}
            >
              <li>
                <FaDeviantart className="mobileNavIcon" />
                <span>Deviation & Additional</span>
              </li>
            </Link>

            <Link
              to="/creation"
              className="mobileNavLinks"
              onClick={toggleShowMobileNav}
            >
              <li>
                <MdEditNote className="mobileNavIcon" />
                <span>Creation</span>
              </li>
            </Link>

            <Link
              to="/utilities"
              className="mobileNavLinks"
              onClick={toggleShowMobileNav}
            >
              <li>
                <FaStickyNote className="mobileNavIcon" />
                <span>Utilities</span>
              </li>
            </Link>

            <Link
              to="/extras"
              className="mobileNavLinks"
              onClick={toggleShowMobileNav}
            >
              <li>
                <MdEditCalendar className="mobileNavIcon" />
                <span>Extras</span>
              </li>
            </Link>

            <Link
              to="/secondary-sales"
              className="mobileNavLinks"
              onClick={toggleShowMobileNav}
            >
              <li>
                <MdMoney className="mobileNavIcon" />
                <span>Secondary Sales</span>
              </li>
            </Link>

            <Link
              to="/dashboard"
              className="mobileNavLinks"
              onClick={toggleShowMobileNav}
            >
              <li>
                <MdOutlineDashboard className="mobileNavIcon" />
                <span>Dashboard</span>
              </li>
            </Link>
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default MobileNav;
